import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';
import Helmet from 'react-helmet';
import {
  IoIosCall,
  IoIosCheckmark,
  IoIosLink,
  IoIosStar,
  IoIosStarHalf,
} from 'react-icons/io';

import withLocation from '../with-location';

const capitalise = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const HeroCTA = withLocation(({ search }) => {
  const isBrowser = typeof window !== 'undefined';
  const { from, to, location, interstate } = search;

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
          googleMapsAddress
        }
      }
    }
  `);

  const { phone, phoneFormatted, googleMapsAddress } = data.site.siteMetadata;

  const CTA = () => {
    if (from && to) {
      return (
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="text-brand-orange">Experienced</span> <br />
          {capitalise(from)} to <br />
          {capitalise(to)} <br /> removalist
        </p>
      );
    }
    if (location) {
      return (
        <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          <span className="text-brand-orange">Experienced</span> <br />
          {capitalise(location)}
          {interstate && (
            <>
              {' '}
              <br /> Interstate
            </>
          )}
          <br /> removalist
        </p>
      );
    }
    return (
      <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
        <span className="text-brand-orange">Experienced</span> <br />
        interstate and <br />
        regional <br /> removalist
      </p>
    );
  };
  return (
    <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
      {from && to && (
        <Helmet
          title={`Experienced ${capitalise(from)} to ${capitalise(
            to
          )} Removalist`}
        />
      )}
      {location && (
        <Helmet title={`Experienced ${capitalise(location)} Removalist`} />
      )}
      <div className="flex">
        <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
          <CTA />
          <div className="mt-3">
            {[
              {
                id: 1,
                title: '26+ years experience',
              },
              {
                id: 2,
                title: 'Caring & reliable service',
              },
              {
                id: 3,
                title: 'Packing available',
              },
              {
                id: 4,
                title: 'Excellent value',
              },
            ].map((item) => (
              <div key={item.id} className="flex items-center font-semibold">
                <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
                <span className="flex-shrink-0 ml-1 uppercase">
                  {item.title}
                </span>
              </div>
            ))}
            <a
              href={googleMapsAddress}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center font-semibold hover:text-brand-orange"
            >
              <IoIosCheckmark className="flex-shrink-0 -ml-2 text-4xl text-brand-orange" />
              <span className="flex items-center flex-shrink-0 ml-1 uppercase">
                4.5&nbsp;
                <span aria-hidden="true" className="flex text-brand-orange">
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStar />
                  <IoIosStarHalf />{' '}
                </span>
                <span className="ml-1"> on Google</span>
                <span className="ml-1">
                  <IoIosLink />
                </span>
              </span>
            </a>
          </div>
          <div className="flex flex-wrap -mx-2">
            <p className="flex-shrink-0 w-full px-2 sm:w-auto">
              <Link
                to="https://www.frontlineremovals.com.au/furniture-removal/#moving-page-top-form"
                // @ts-ignore
                offset="12"
                className="flex items-center justify-center w-full mt-4 shadow sm:w-56 hover:shadow-md button"
              >
                Get instant quote
              </Link>
            </p>
            <p className="flex-shrink-0 w-full px-2 sm:w-auto">
              <a
                onClick={() => {
                  // @ts-ignore
                  if (isBrowser && window.fbq) {
                    // @ts-ignore
                    window.fbq('track', 'Contact');
                  }
                }}
                href={`tel:${phone}`}
                className="flex items-center justify-center w-full mt-4 bg-white shadow sm:w-56 border-brand-orange text-brand-orange hover:shadow-md button"
              >
                <IoIosCall />
                <span className="ml-2">Call: {phoneFormatted}</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </article>
  );
});

// @ts-ignore
HeroCTA.propTypes = {
  search: PropTypes.object,
};

export { HeroCTA };
