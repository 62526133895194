import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IoIosArrowDown, IoIosClose } from 'react-icons/io';
import { animated } from 'react-spring';

import navItems from '../data/nav-items';

function ListItem({ navItem }) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  return (
    <li>
      {navItem.submenu ? (
        <button
          type="button"
          onClick={() => setSubMenuOpen(!subMenuOpen)}
          className="inline-flex items-center justify-between py-2 text-2xl text-white uppercase cursor-pointer focus:outline-none focus:ring transition-color transition-medium hover:text-brand-orange"
        >
          {navItem.submenu && <IoIosArrowDown className="pr-2" />}
          {navItem.title}
        </button>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          to={navItem.submenu ? '#' : `https://www.frontlineremovals.com.au/${navItem.route}`}
          activeClassName="text-brand-orange"
          partiallyActive={navItem.title !== 'Home'}
          className="inline-flex items-center justify-between py-2 text-2xl text-white uppercase transition-color transition-medium hover:text-brand-orange"
        >
          {navItem.submenu && <IoIosArrowDown className="pr-2" />}
          {navItem.title}
        </Link>
      )}
      {navItem.submenu && (
        <ul
          className={`${
            subMenuOpen ? 'block border-t border-b border-gray-600' : 'hidden'
          } z-10 px-2 py-1 -mx-2 text-white transition duration-150 ease-in-out bg-brand-blue`}
        >
          {navItem.submenu.map((submenu) => (
            <ListItem key={submenu.id} navItem={submenu} />
          ))}
        </ul>
      )}
    </li>
  );
}

ListItem.propTypes = {
  navItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    submenu: PropTypes.array,
  }),
};

function MobileMenu({ navAnimations, isOpen, setIsOpen }) {
  return (
    <animated.div
      style={navAnimations}
      className="fixed top-0 bottom-0 right-0 z-20 flex flex-col w-64 px-6 py-2 overflow-y-auto bg-brand-blue lg:hidden"
    >
      <button
        aria-label="Toggle site navigation menu."
        aria-expanded={isOpen}
        aria-controls="nav-menu"
        onClick={() => setIsOpen(false)}
        type="button"
        className="absolute right-0 flex items-center px-2 py-1 mr-6 text-2xl rounded bg-brand-orange text-brand-blue"
      >
        <IoIosClose />
      </button>
      <ul
        id="nav-menu"
        className="relative py-4 mt-12 text-right font-display lg:hidden"
      >
        {navItems.map((navItem) => (
          <ListItem key={navItem.id} navItem={navItem} />
        ))}
      </ul>
    </animated.div>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  navAnimations: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export { MobileMenu };
