const defaultTheme = require('tailwindcss/defaultTheme');
const aspectRatio = require('@tailwindcss/aspect-ratio');
const lineClamp = require('@tailwindcss/line-clamp');
const typography = require('@tailwindcss/typography');

// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        brand: {
          blue: '#152735',
          'blue-darkest': '#091117',
          orange: '#f15c2f',
          gray: '#f3f1f2',
        },
      },
      fontFamily: {
        display: ['Abolition', ...defaultTheme.fontFamily.sans],
        sans: ['Montserrat', ...defaultTheme.fontFamily.sans],
      },
      height: {
        9: '2.25rem',
      },
      spacing: {
        '56-percent': '56%',
      },
      maxWidth: {
        1440: '1440px',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            'ol > li::before': {
              color: theme("colors.['orange-orange']"),
            },
            'ul > li::before': {
              backgroundColor: theme("colors.['orange-orange']"),
            },
          },
        },
      }),
    },
  },
  variants: {
    extend: {
      display: ['group-hover'],
      margin: ['first'],
    },
  },
  plugins: [lineClamp, aspectRatio, typography],
};
